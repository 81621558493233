import React, { Component } from "react"
import { ImageDisplay } from "utils"
import styles from "./imageBloc.module.css"

class ImageBloc extends Component {
  constructor(props) {
    super();

    for (var i = props.imgs.length - 1; i >= 0; i--) {
      this['ref' + i] = React.createRef();
    }

    this.handleScroll = this.handleScroll.bind(this);
  }
  componentDidMount() {
    this.showElement();
    window.addEventListener('scroll', this.handleScroll);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }
  showElement() {
    if (typeof window !== `undefined`) {
      for (var i = this.props.imgs.length - 1; i >= 0; i--) {
        if (this['ref' + i].current && this['ref' + i].current.offsetTop < window.scrollY + window.innerHeight){
          this['ref' + i].current.style.opacity = 1;
        }
      }
    }
  }
  handleScroll() {
    this.showElement();
  }
  render() {
    return (
      <div 
        className={styles.imageBlocContain}
      >
        {this.props.imgs && this.props.imgs.map((elt, index) =>
          <span
            ref={this['ref' + index]}
            key={index}
            onClick={() => this.props.onImageClick(this.props.lightBoxIndexStart + index)}
          >
            <ImageDisplay
              img={elt}
            />
          </span>
        )}
      </div>
   );
  }
}

export default ImageBloc;
